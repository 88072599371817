<template>
    <section>
        <div class="venueItem" >
            <div class="itemDate" v-if="item.itemTypeTxt && ['酒店住宿'].includes(item.itemTypeTxt)">
                {{item.dtStart | formatDateZh('MM月dd日')}} ~ {{item.dtEnd | formatDateZh('MM月dd日')}}
            </div>
            <div class="itemDate" v-else>
                {{item.dtStart | formatDateZh('MM月dd日 hh:mm')}} ~ {{item.dtEnd | formatDateZh('MM月dd日 hh:mm')}}
            </div>
            <div class="itemType" v-if="item.itemType == 1">
                <span>{{item.itemName}}</span>
                <span>{{item.qty1}} 人</span>
                <span>{{isTear}}</span>
            </div>
            <div class="itemType" v-if="item.itemType == 2">
                <span>{{item.itemName}}</span>
                <span>{{$$(item, 'itsItemData', 'mealType')}}</span>
                <span>{{item.qty1}} 人</span>
            </div>
            <div class="itemType" v-if="item.itemType == 3">
                <span>{{item.itemName}}</span>
                <span>{{item.qty1}} {{item.unit1}}</span>
                <span>{{item.qty2}} {{item.unit2}}</span>
            </div>
            <div class="itemType" v-if="item.itemType == 4">
                <span>{{item.itemName}}</span>
                <span>{{item.qty1}} {{item.unit1}}</span>
            </div>
            <p class="descr" v-if="item.descr">{{item.descr}}</p>
            <div class="itemSupplier">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-jiudian"></use>
              </svg>
              <div>
                <p v-for="(list, i) in item.itsSuppliersV2" :key="i">{{list.name}}</p>
              </div>
            </div>
            <div class="itemDescr" v-show="isCanAdd">
              <div class="btnBox" >
                <div class="btnItem" @click="deleteItem">删除</div>
                <div class="btnItem primary" @click="goDetail">编辑</div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'venueItem',
        props: {
            item: {
                type: Object,
                default () {
                    return {}
                }
            },
            index: {
                type: Number,
                default: 0
            },
            demandType: {
                type: Number,
                default: 0
            },
            isCanAdd: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isTear() {
                if (
                    this.item.itsItemData &&
                    this.item.itsItemData.teaBreak &&
                    this.item.itsItemData.teaBreak.length > 0 &&
                    this.item.itsItemData.teaBreak[0].hasBreak
                ) {
                    return "有茶歇";
                } else {
                    return "无茶歇";
                }
            }
        },
        data () {
            return {

            }
        },
        methods: {
            deleteItem () {
                this.$dialog.confirm({
                    message: '是否确认删除该需求',
                }).then(async () => {
                    let tenantTxt = ["pfizer", "viatris"];
                    let funName = tenantTxt.includes(this.$cookie.get("tenant")) ? 'deleteItem' : 'deleteItemOld'
                    let params = {
                        proposalId: this.$SmartStorage.get("proposalId"),
                        itemId: this.item.itemId
                    }
                    let response = await this.$service[funName](params);
                    if (response.success) {
                        this.$toast("删除成功！");
                        this.$root.$eventHub.$emit("againRendering", true);
                    }
                }).catch(() => {});
            },
            goDetail () {
                if (!this.isCanAdd) {
                    return
                }
                this.$SmartStorage.set('venueItem', this.item)
                this.$SmartStorage.set('venueList', this.item.itsSuppliersV2)
                this.$router.push(`/addDemand?demandType=${this.demandType}`)
            }

        }
    }
</script>
<style lang="scss" scoped>
    @import "venueItem";
</style>
