<template>
    <section>
      <sm_header title='酒店需求' :smHleftEvent='closeWebview'></sm_header>
        <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
            <i-scrollBox class="opOrderListScroll"  @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
                <template v-slot:scrollBox>
                    <div class="venueItemBox" v-if="venueList.length">
                        <div class="itemTitle">酒店会场</div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in venueList" :key="item.key">
                                    <venue-item :demandType="0" :item="item" :index="index" :isCanAdd="isCanAdd"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="mealList.length">
                        <div class="itemTitle">酒店用餐</div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in mealList" :key="item.key">
                                    <venue-item :demandType="1" :item="item" :index="index" :isCanAdd="isCanAdd"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="accommodationList.length">
                        <div class="itemTitle">酒店住宿</div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in accommodationList" :key="item.key">
                                    <venue-item :demandType="2" :item="item" :index="index" :isCanAdd="isCanAdd"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="otherList.length">
                        <div class="itemTitle">酒店其他</div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in otherList" :key="item.key">
                                    <venue-item :demandType="3" :item="item" :index="index" :isCanAdd="isCanAdd"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </i-scrollBox>
        </div>
        <add-button v-show="isCanAdd" @click="addDemandType" :optionsList="demandTypeList"></add-button>
        <div class="largeBtn" v-if="isCanAdd && isDemand" @click="StartInquiry">{{rightTxt}}</div>
    </section>
</template>
<script>
    import iScrollBox from "@/components/iScrollBox/iScrollBox";
    import mixin from './requirementsList.js'
    import venueItem from "./components/venueItem";
    import generalBridge from "@/plugin/generalBridge.js";
    import addButton from "@/components/AddButton/AddButton";
    export default {
        mixins:[mixin],
        components: {
            iScrollBox,
            venueItem,
            addButton
        },
        data () {
            return {
                visible: false,
                fromPage: false,
                demandTypeList: [],
                wrapperHeight: 0,
            }
        },
        mounted() {
            this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
        },
        computed: {
          isDemand () {
            return this.venueList.length || this.mealList.length || this.accommodationList.length || this.otherList.length
          }
        },
        created() {
            this.$root.$eventHub.$on("againRendering", () => {
                this.getItems();
            });
            this.$SmartStorage.remove('orderId')
            this.initFromPage()
            this.getItems()
            this.getAddDemandBtn()

        },
        methods: {
            // 初始化fromPage
            initFromPage() {
                let fromPage = this.$route.query;
                this.fromPage = fromPage.fromPage ? fromPage.fromPage : false;
            },
            closeWebview () {
              if (this.fromPage) {
                this.$router.back();
              } else {
                generalBridge.propy.call(this)
              }
            },
            scrollTop(){
                this.loadType = 'top'
                this.getItems()
            },
            scrollBottom () {
                this.getItems()
            },
            addDemandType (item) {
              this.$SmartStorage.remove('venueItem')
                this.$router.push(item.path)
            }
        },
        beforeDestroy() {
            // 清除Bus
            this.$root.$eventHub.$off("againRendering");
        }
    }
</script>
<style scoped lang="scss">
.opOrderListScroll {
  padding: .44rem 0 1rem;
  .itemTitle {
    text-align: left;
    padding-left: .2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .16rem;
    font-weight: bold;
    color: #13161B;
  }
}
</style>
