<template>
    <div class="i-add-button2" tabindex="0" @click="onClick" @touchmove="touchmove($event)" @blur="handleBlur" @focusout="handleBlur">
        <i-icon color="#ffffff" :size="30" name="icon-add-bold"/>
        <template v-if="optionsList.length > 0">
            <div class="btn-options" v-show="showBtnOp">
                <div class="options-item"
                     @click.stop="optionsClick(item)"
                     v-for="(item,index) in optionsList" :key="index">
                  <svg class="icon" aria-hidden="true">
                    <use :xlink:href="item.icon"></use>
                  </svg>
                  {{ item.name }}
                </div>
            </div>
            <div class="tra" v-show="showBtnOp"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: "iAddButton",
    props: {
        optionsList: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            showBtnOp: false,
        }
    },
    methods: {
        onClick() {
            if (this.optionsList.length > 0) {
                this.showBtnOp = !this.showBtnOp
                return
            }
            this.$emit("click");
        },
        handleBlur () {
          this.showBtnOp = false
        },
        touchmove(event) {
            event.preventDefault(); //阻止默认事件传播
            const _circle = event.targetTouches[0];
            const _create_event = document.getElementsByClassName("i-add-button2")[0];
            const _clientWidth = document.documentElement.clientWidth;
            const _clientHeight = document.documentElement.clientHeight;
            if (_circle.pageX > 28 && _circle.pageX < _clientWidth - 28) {
                _create_event.style.left = -28 + _circle.pageX + "px";
            }
            if (_circle.clientY > 118 && _circle.clientY < _clientHeight - 84) {
                _create_event.style.top = -28 + _circle.clientY + "px";
            }
        },
        optionsClick(item) {
            this.showBtnOp = false
            this.$emit("click", item);
        }
    },
};
</script>

<style lang="scss">
.i-add-button2 {
    cursor: pointer;
    position: fixed;
    right: 0.35rem;
    bottom: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--themeColor);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
    outline: none;
    .btn-options {
        width: 1.1rem;
        //height: 1.92rem;
        background: #FFFFFF;
        box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
        outline: none;
        border-radius: 0.05rem;
        position: absolute;
        right: 0;
        bottom: 0.62rem;
        .options-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 0.47rem;
            text-align: center;
            vertical-align: middle;
            line-height: 0.47rem;
            font-size: 0.16rem;
            font-weight: 500;
            color: #13161B;
            border-bottom: 1px solid #F5F5F5;
          .icon{
            width: .15rem;
            height: .15rem;
            margin-right: .05rem;
          }
        }
        :last-child {
            border-bottom: unset;
        }
    }
     .tra {
         width: 0;
         height: 0;
         border-left: 0.1rem solid transparent;
         border-right: 0.1rem solid transparent;
         border-top: 0.1rem solid white;
         position: absolute;
         top: -0.15rem;
         right: 0.1rem;
     }
}
</style>
